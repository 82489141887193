import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { staticImgContainer, primaryColour, InlineExternalLink } from "../../styles/component-stylings";
import { DSX_DANDELION_PROGRAM_LINK } from "../../helpers/links";

import DandelionVideo from "../../videos/dandelion_video.mp4";

const ColumnBlockContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    // change for small
    @media (max-width: 550px) {
        flex-direction: column;
    }
`;

const IconContainer = styled.div`
    height: 200px;
    flex: 1 0 calc(100% / 3);
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 100ms ease-in;

    & > p {
        text-align: center;
        margin-bottom: 0;
    }

    & > h4 {
        text-align: center;
        margin-bottom: 0;
    }

    &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    }
`;

const QuoteContainer = styled.blockquote`
    all: unset;
    display: block;
    background: white;
    padding: 1.25rem 2rem 1.25rem 2rem;
    text-align: center;
    margin: 4rem auto;
    width: 50%;
    max-width: 500px;
    border-radius: 1rem;
    border: solid 2px ${primaryColour};
    box-shadow: 1rem 1rem 0 0 ${primaryColour};
    font-weight: bold;
    font-style: italic;
`;

const VideoContainer = styled.div`
    text-align: center;
    padding: 0rem 2rem 0rem 2rem;
    margin: 2rem auto;
    max-width: 80%; // idk how to center this properly
`;

const PAGE_TITLE = "Dandelion Program";

const DandelionProgramPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='Overview page for the Digital Surveillance Collection Dandelion Program' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 10%" }}
                    src='../../images/diversity-inclusion-banner.png'
                    alt='Technical Specialist'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>
                    Through a partnership between DSC and{" "}
                    <InlineExternalLink href={DSX_DANDELION_PROGRAM_LINK} target='_blank' rel='external'>
                        DXC Technologies' Dandelion Program
                    </InlineExternalLink>{" "}
                    a specialised recruitment process has been set up for members of the neurodivergent community to find fulfilling careers with the AFP.
                </p>
                <p>
                    By demonstrating technical aptitude, a <strong> 2-year traineeship with DSC </strong> can be obtained. This will see trainees develop the
                    innate abilities they already have into software development and data analytics skills to support the AFP's world-leading digital
                    surveillance and data analytics programs.
                </p>

                <h2>Benefits of joining the Dandelion Program</h2>
                <ColumnBlockContainer>
                    <IconContainer>
                        <h4>Strengths-based Recruitment</h4>
                        <p>Tailored for role-specific aptitude and workplace motivations 100% supported</p>
                    </IconContainer>
                    <IconContainer>
                        <h4>Prioritises Trainee Wellbeing</h4>
                        <p>Builds work, life, and mental health skills for greater resilience while delivering valuable business outcomes</p>
                    </IconContainer>
                    <IconContainer>
                        <h4>Ongoing Support and Training</h4>
                        <p>For trainee, managers, and co-workers to understand working styles that enable individuals to be at their best</p>
                    </IconContainer>
                    <IconContainer>
                        <h4>Full-time Onsite Support Consultant</h4>
                        <p>Provides prompt issue resolution and de-escalation, improved employee relation and performance management</p>
                    </IconContainer>
                </ColumnBlockContainer>

                <QuoteContainer>"The Australian Federal Police is committed to improving diversity and inclusion to reflect our community."</QuoteContainer>

                <p>On completion of the contracted traineeship, employees will be given the opportunity to apply for any available role in DSC.</p>
                <p>
                    For more information regarding the Dandelion Program, please view the <Link to='/contact-us'>Contact Us</Link> page.
                </p>

                <h2>Hear what our trainees have to say</h2>
                <VideoContainer>
                    <video width='60%' controls>
                        <source src={DandelionVideo} type='video/mp4' />
                    </video>
                </VideoContainer>

                <p>
                    <em>This page was created by Dandelion members.</em>
                </p>
            </PageBlock>
        </PageLayout>
    );
};

export default DandelionProgramPage;
